$-outline-width: 0.0625rem
$-gap: 0.6875em

.wrapper
	--Button-brand-color: var(--global-colors-brand)
	--Button-danger-color: #ed3129
	--Button-padding-top-bottom: 0.5em
	--Button-line-height: 1.35em
	--Button-background-color: var(--Button-base-color)
	--Button-content-color: var(--Button-other-color)
	display: inline-flex
	align-items: center
	justify-content: center
	column-gap: $-gap
	font-size: var(--Button-font-size)
	line-height: var(--Button-line-height)
	padding: var(--Button-padding-top-bottom) 1em
	color: var(--Button-content-color)
	border: $-outline-width solid var(--Button-base-color)
	border-radius: 0.3125rem
	text-transform: uppercase
	text-align: center
	font-weight: 700
	position: relative
	z-index: 0
	overflow: hidden
	cursor: pointer
	font-family: inherit
	text-decoration: none

	&::before,
	&::after
		content: ''
		position: absolute
		inset: -$-outline-width
		border-radius: inherit

	&::before
		box-shadow: 0 0 0.3125rem var(--Button-highlight-color)
		opacity: 0
		transition: opacity 0.2s

	&::after
		background-color: var(--Button-background-color)
		z-index: -2 // -1 would be enough, but this is to make sure it's always behind the ripple
		transform-origin: 0 50%

	&.is_outline
		--Button-content-color: var(--Button-base-color)
		border-color: var(--Button-base-color)

		&::after
			opacity: 0.3
			transform: scaleX(clamp(0, var(--Button-progress, 0), 1))

	&.is_fullWidth
		width: 100%

	&.is_size_medium
		--Button-font-size: 1rem

	&.is_size_large
		--Button-font-size: 1.25rem

	&.is_size_small
		--Button-font-size: 0.75rem

	&.is_variant_primary
		--Button-base-color: var(--Button-brand-color)
		--Button-other-color: #ffffff
		--Button-highlight-color: #2a2928

	&.is_variant_secondary
		--Button-base-color: #e7e7e8
		--Button-other-color: #2a2928
		--Button-highlight-color: #cecece

		&.is_outline
			--Button-base-color: #2a2928

	&.is_variant_danger
		--Button-base-color: var(--Button-danger-color)
		--Button-other-color: #ffffff
		--Button-highlight-color: #f58c87

	&.is_variant_success
		--Button-base-color: var(--global-colors-green-900)
		--Button-other-color: #ffffff
		--Button-highlight-color: #2c9515

		&:not(.is_outline)
			--Button-background-color: var(--global-colors-green-50)
			--Button-content-color: var(--Button-base-color)

	&.is_variant_dark
		--Button-base-color: #28282a
		--Button-other-color: #ffffff
		--Button-highlight-color: #{lighten(#2a2928, 10%)}

	&.is_variant_light
		--Button-base-color: #ffffff
		--Button-other-color: #28282a
		--Button-highlight-color: #cecece

	&.is_round
		border-radius: calc(1em * var(--Button-line-height) + var(--Button-padding-top-bottom))

	&.is_caseNormal
		text-transform: none

	&.is_fontWeightNormal
		font-weight: 400

	&:not(:disabled):not(.is_visuallyDisabled)
		&:hover::before,
		&:focus::before
			opacity: 1

		&:active
			transform: translateY(1px)

	&:disabled,
	&.is_visuallyDisabled
		opacity: 0.5
		cursor: not-allowed
	&.is_disableGutters
		padding: 0

.icon
	display: flex

.content

	&:empty

		&::before
			// Forces line height and vertical alignment
			content: ' '
			width: 0
			display: inline-block

		.icon + &,
		& + .icon
			margin-left: -$-gap

		.icon + & + .icon
			margin-left: 0
