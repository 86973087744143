@use 'sass:math'

// Normalizes height to 1em while preserving aspect ratio
=normalizeSize($intrinsicWidth, $intrinsicHeight)
	$height: 1em

	width: math.div($intrinsicWidth, $intrinsicHeight) * $height
	height: $height

.icon
	// Add all svg icons and corresponding dimensions
	color: blue

	&-go
		+normalizeSize(512, 512)

	&-kilomayoSmall
		+normalizeSize(39, 39)

	&-kilomayoLarge
		+normalizeSize(857, 160)

	&-addWorker,
	&-arrowDown,
	&-arrowRight,
	&-backspace,
	&-blenderOff,
	&-blenderOn,
	&-bottle,
	&-bottles,
	&-boxOpen,
	&-business,
	&-calendar,
	&-cartOff,
	&-cartOn,
	&-checkMarkThin,
	&-clock,
	&-clockThin,
	&-countryLocation,
	&-cross,
	&-crossedNote,
	&-crossInCircle,
	&-deliveryOff,
	&-deliveryOn,
	&-email,
	&-ellipsis,
	&-exit,
	&-friendsOff,
	&-friendsOn,
	&-fruit,
	&-ordersOff,
	&-ordersOn,
	&-list,
	&-locked,
	&-marker,
	&-minus,
	&-move,
	&-note,
	&-offline,
	&-pin,
	&-pinOff,
	&-pinOn,
	&-plus,
	&-posOff,
	&-posOn,
	&-productsOff,
	&-productsOn,
	&-recipe,
	&-receipt,
	&-salesOn,
	&-salesOff,
	&-settings,
	&-shiftsOff,
	&-shiftsOn,
	&-statsOff,
	&-statsOn,
	&-swap,
	&-team,
	&-trash,
	&-unlocked,
	&-wikiOff,
	&-wikiOn
		+normalizeSize(40, 40)

	&-schedule,
	&-print
		+normalizeSize(20, 21)

	&-checkMark
		+normalizeSize(17, 14)

	&-circleCheckMark
		+normalizeSize(20, 21)

	&-editPencil
		+normalizeSize(14, 14)

	&-circlePlus,
	&-circleMinus,
	&-circleCancel,
	&-credit,
	&-creditCard,
	&-cashBill,
	&-invoice,
	&-cash
		+normalizeSize(24, 24)

	&-gut,
	&-gutFill
		+normalizeSize(26, 40)

	&-goodlokText
		+normalizeSize(699, 183)

	&-g
		+normalizeSize(26, 54)

	&-googlePayLogo
		+normalizeSize(435.97, 173.13)
		display: block

	&-applePayLogo
		+normalizeSize(540, 210)
		display: block

	&-facebookLogo
		+normalizeSize(60.734, 60.734)
		display: block

	&-appleLogo
		+normalizeSize(305, 305)
		display: block

	&-bottleFreshBig
		+normalizeSize(22, 24)
		display: block
		background: linear-gradient(90deg, rgba(0, 0, 0, 0.2) -0.42%, rgba(255, 255, 255, 0) 33.6%, rgba(255, 255, 255, 0.26) 45.61%, rgba(255, 255, 255, 0) 57.61%, rgba(0, 0, 0, 0.2) 99.64%)
