@use 'sass:math'

$text-offset-right-left: 1em

.wrapper
	cursor: pointer

.content
	position: relative
	display: grid
	grid-template-columns: 1fr

.view_size_compact
	font-size: 0.78em

.label
	font-size: 1.25em
	line-height: 1.2
	font-weight: 300

.input
	$font-size: 1.125em
	$height: 2.8em
	$border-width: 1px

	display: flex
	align-items: center
	width: 100%
	font-family: inherit
	font-size: $font-size
	height: $height
	line-height: 1
	border: $border-width solid var(--global-colors-gray-500)
	background-color: #ffffff
	border-radius: 0.28em
	padding: calc(math.div($height - $font-size, 2) - 2 * $border-width) $text-offset-right-left
	box-sizing: border-box

	&[type='number']
		moz-appearance: textfield

		&::-webkit-outer-spin-button, &::-webkit-inner-spin-button
			-webkit-appearance: none
			margin: 0

	&[type='date']
		appearance: textfield
		-webkit-appearance: textfield

		&::-webkit-datetime-edit,
		&::-webkit-datetime-edit-fields-wrapper
			display: flex
			align-items: center
			font-family: 'NeueVektor'
			font-weight: 400
			color: #000000

		&::-webkit-datetime-edit-ampm-field,
		&::-webkit-datetime-edit-day-field,
		&::-webkit-datetime-edit-hour-field,
		&::-webkit-datetime-edit-millisecond-field,
		&::-webkit-datetime-edit-minute-field,
		&::-webkit-datetime-edit-month-field,
		&::-webkit-datetime-edit-second-field,
		&::-webkit-datetime-edit-week-field,
		&::-webkit-datetime-edit-year-field,
		&::-webkit-datetime-edit-text
			color: #000000
			font-family: 'NeueVektor'
			font-weight: 400

	&[type="color"]
		$color1: #FFFFFF
		$color2: #E2DFDA
		$size: 0.25rem
		cursor: pointer
		border-radius: 50%
		border: 0.0625rem solid var(--global-colors-gray-500)
		width: 3.75rem
		aspect-ratio: 1
		height: auto // reset defined height
		padding: .625rem
		&::-webkit-color-swatch-wrapper
			-webkit-appearance: none
			-moz-appearance: none
			appearance: none
			display: none
		&::after
			content: ''
			display: block
			width: 3.75rem
			aspect-ratio: 1
			border-radius: 50%
			background-color: var(--Input-color)
			border: 0.0625rem solid var(--Input-color-border)
		&.input_empty
			&::after
				background-image: repeating-linear-gradient(-45deg, $color1, $color1 $size, $color2 $size, $color2 2 * $size)

	&:disabled
		border-color: #c2c0be
		background-color: #f6f4f2

	&:invalid
		border-color: var(--global-colors-red-900)
		background-color: var(--global-colors-red-300)

.note
	margin-top: .25em
	margin-bottom: .25em

.view_labelPosition_above
	.content
		gap: 0.8125em

	.label
		grid-row: 1

.view_labelPosition_after .content
	grid-template-columns: 1fr auto
	gap: 0.625em
	grid-auto-flow: dense
	align-items: center

.view_labelPosition_inside
	.input
		padding-top: 1.5em
		height: 3.44em

		&::placeholder
			visibility: hidden
			opacity: 0

	.label
		position: absolute
		top: 1.75em
		transform: translateY(-50%)
		left: 0
		width: 100%
		display: flex
		align-items: center
		pointer-events: none
		font-size: 1.125em
		padding-right: $text-offset-right-left
		padding-left: $text-offset-right-left
		color: #28282a
		opacity: 0.3
		transition-property: opacity, transform, font-size
		transition-duration: 0.2s
		transition-timing-function: ease-in-out
		transform-origin: $text-offset-right-left

	.input:focus ~ .label,
	.input:not(:placeholder-shown) ~ .label
		opacity: 1
		transform: scale(0.66) translate3d(0, -2.125em, 0)

.label
	/* Last column
	grid-column: -2

.keepNoteSpace
	min-height: 2.75em

.view_invalid
	.input
		border-color: var(--global-colors-red-900)

.view_textarea
	.input
		min-height: 10em
		resize: none
		min-width: 100%

	&.view_size_compact .input
		min-height: 6em
