.wrapper
	border-radius: 0.3125em
	padding: 0.125em 0.3125em
	font-size: 1rem
	text-align: center
	display: flex
	justify-content: center
	align-items: center
	column-gap: 0.2em
	white-space: nowrap
	font-weight: 700

	&.is_highlighted
		font-weight: 700

	&.is_variant_success
		background-color: var(--global-colors-green-50)
		color: var(--global-colors-green-900)

	&.is_variant_error
		background-color: var(--global-colors-red-300)
		color: var(--global-colors-red-900)

	&.is_variant_warning
		background-color: var(--global-colors-orange-100)
		color: var(--global-colors-orange-900)

	&.is_variant_neutral
		background-color: var(--global-colors-offWhite-300)
		color: var(--global-colors-dark)

	&.is_variant_info
		background-color: #dde1ff
		color: #404dff

.icon
	display: grid
	position: relative

.content
	//
