@use 'sass:math'

.tag
	color: var(--global-colors-gray-500)
	border-radius: .625rem
	line-height: math.div(21, 16)
	font-weight: 700
	text-align: left
	border: 1px solid transparent
	display: flex
	justify-content: space-between
	&:has(.input:checked)
		color: var(--global-colors-green-900)
	&.view_withOutline
		padding: .625rem
		&:has(.input:checked)
			border: 1px solid var(--global-colors-green-900)
			background: var(--global-colors-green-50)

	&.view_disabled
		opacity: .5
		pointer-events: none

.tagIn
	display: flex
	align-items: center
	gap: .5em
	flex-shrink: 0

.input
	position: absolute
	opacity: 0
	visibility: hidden
	pointer-events: none
	&:checked
		~ .customCheckbox
			background: var(--global-colors-green-900)
			border: 1.5px solid var(--global-colors-green-900)

.tagIn
	user-select: none

.customCheckbox
	background: transparent
	border: 1.5px solid var(--global-colors-gray-500)
	width: 1.5625em
	height: 1.5625em
	border-radius: .3125em
	position: relative
	&::after
		content: ""
		position: absolute
		top: 50%
		left: 50%
		transform: translate(-50%, -70%) rotate(-45deg)
		width: .5em
		height: .3125em
		border-left: .125rem solid #FFFFFF
		border-bottom: .125rem solid #FFFFFF
