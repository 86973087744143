/* @TODO: unify with Input.module.sass

.wrapper
	display: grid
	gap: 0.8125em
	cursor: pointer

.label
	font-size: 1.25em
	line-height: 1.2
	font-weight: 300

.input
	display: block
	width: 100%
	font-size: 1.125em
	height: 2.8em
	border: 1px solid #979797
	background-color: #ffffff
	border-radius: 0.28em
	padding-left: 0.9em

	// padding-right: 0.9em;
	box-sizing: border-box
	position: relative
	appearance: none
	padding-right: 1.5em

.inputWrapper
	position: relative

	&::after
		content: ''
		position: absolute
		right: 0.87em
		top: 50%
		transform: translateY(-20%) rotate(45deg)
		width: 0.4em
		height: 0.4em
		color: #979797
		color: currentColor
		border-right: 1px solid
		border-bottom: 1px solid
		transform-origin: 100% 100%

.input:disabled
	border-color: #c2c0be
	background-color: #f6f4f2
